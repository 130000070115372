// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.needSupp {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    margin: 10px 0;
    cursor: pointer;
}

.needSupp input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.needSupp input[type="checkbox"]:checked {
    accent-color: #3498db;
}

.needSupp input[type="checkbox"] + span {
    vertical-align: middle;
}
.clubInput{
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/addclub.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,WAAW;IACX,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".needSupp {\n    display: flex;\n    align-items: center;\n    font-family: Arial, sans-serif;\n    font-size: 14px;\n    color: #333;\n    margin: 10px 0;\n    cursor: pointer;\n}\n\n.needSupp input[type=\"checkbox\"] {\n    margin-right: 10px;\n    width: 18px;\n    height: 18px;\n    cursor: pointer;\n}\n\n.needSupp input[type=\"checkbox\"]:checked {\n    accent-color: #3498db;\n}\n\n.needSupp input[type=\"checkbox\"] + span {\n    vertical-align: middle;\n}\n.clubInput{\n    width: 95%;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
