// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-button{
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}
.right-button{
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/photodisplay.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,OAAO;IACP,2BAA2B;IAC3B,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,QAAQ;IACR,QAAQ;IACR,2BAA2B;IAC3B,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".left-button{\n    top: 50%;\n    left: 0;\n    transform: translateY(-50%);\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n.right-button{\n    top: 50%;\n    right: 0;\n    transform: translateY(-50%);\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
