// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    margin: 20px;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.textInput{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}
.submitButton{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB","sourcesContent":["body{\n    margin: 20px;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n}\n.textInput{\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-sizing: border-box;\n}\n.submitButton{\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: none;\n    border-radius: 5px;\n    background-color: #007BFF;\n    color: white;\n    cursor: pointer;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
