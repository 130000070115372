// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
    border: none;
    border-radius: 3px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Fav.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background-color: red;\n    color: white;\n    padding: 5px 10px;\n    cursor: pointer;\n    font-size: 18px;\n    border: none;\n    border-radius: 3px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
