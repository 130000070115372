// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egg::before {
    content: "\\2665";
    font-size: 2rem;
    color: red;
}

.application_form{
    /* display: flex; */
    /* flex-direction: column; */
    /* vertical-align: top; */
    position: absolute;
    /* min-height: 200vh; */
    width: 80vw;
    height: 80vh;
}

.row{
    width: 50vw;
}

#Select1{
    width: 10vw;
}`, "",{"version":3,"sources":["webpack://./src/application.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".egg::before {\n    content: \"\\2665\";\n    font-size: 2rem;\n    color: red;\n}\n\n.application_form{\n    /* display: flex; */\n    /* flex-direction: column; */\n    /* vertical-align: top; */\n    position: absolute;\n    /* min-height: 200vh; */\n    width: 80vw;\n    height: 80vh;\n}\n\n.row{\n    width: 50vw;\n}\n\n#Select1{\n    width: 10vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
