// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title {
    display: inline;
}
.scrollable-content {
    max-height: 100vh;
    overflow-y: auto;
    width: 40vw;
}
.viewApp{
    overflow-y: scroll;
    flex: auto;
}
select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.applicationContainer {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
    border: none;
    border-radius: 3px;
  }
  
`, "",{"version":3,"sources":["webpack://./src/viewApp.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".Title {\n    display: inline;\n}\n.scrollable-content {\n    max-height: 100vh;\n    overflow-y: auto;\n    width: 40vw;\n}\n.viewApp{\n    overflow-y: scroll;\n    flex: auto;\n}\nselect {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.applicationContainer {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-bottom: 10px;\n}\n\n.close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background-color: red;\n    color: white;\n    padding: 5px 10px;\n    cursor: pointer;\n    font-size: 18px;\n    border: none;\n    border-radius: 3px;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
