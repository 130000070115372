// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.question-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.question {
  font-size: 22px;
  margin-bottom: 20px;
  color: #333;
}

.options label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}

.options input {
  margin-right: 10px;
}

.submit {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.result {
  margin-top: 20px;
  font-size: 18px;
  color: #d32f2f;
}
`, "",{"version":3,"sources":["webpack://./src/stuPref.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  background-color: #f9f9f9;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  margin: 0;\n}\n\n.question-container {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n  max-width: 800px;\n  width: 100%;\n}\n\n.question {\n  font-size: 22px;\n  margin-bottom: 20px;\n  color: #333;\n}\n\n.options label {\n  display: block;\n  margin-bottom: 10px;\n  font-size: 18px;\n  color: #555;\n}\n\n.options input {\n  margin-right: 10px;\n}\n\n.submit {\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\nbutton:hover {\n  background-color: #45a049;\n}\n\n.result {\n  margin-top: 20px;\n  font-size: 18px;\n  color: #d32f2f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
